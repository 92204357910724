<template>
  <div
      class="card multisteps-form__panel border-radius-xl bg-white"
      data-animation="FadeIn"
  >
    <div class="multisteps-form__content">
        <!-- Card body -->
        <div class="card-header border-1 bg-transparent">
          <h5 class="font-weight-bolder mb-0">Envío de {{ $store.state.seleccion === 'SMS' ? 'SMS' : 'Whatsapp'}} </h5>
          <p class="mb-0 text-sm">Confirmación del envío</p>
        </div>
        <div class="card-body">
          <h6 class="font-weight-bolder">Vista previa del envío</h6>
          <p class="small">Se muestra un ejemplo con uno de los números.</p>

          <div v-if="$store.state.seleccion === 'Whatsapp' && sendingLocal" class="d-flex justify-content-center">
            <div v-for="(row, index) in selected.slice(0, 1)" :key="index" class="preview-container">
              <WhatsappMessagePreview
                  :header="row.exampleHeader"
                  :media="row.messageMediaType ? mediaType(row.messageMediaType) : ''"
                  :body="row.bodyExample"
                  :footer="row.footer"
                  :botones="botonesData(row)"
              />
            </div>
          </div>
          <div v-else-if="$store.state.seleccion === 'SMS' && sendingLocal" class="d-flex justify-content-center">
            <div v-for="(row, index) in sendingLocal.slice(0, 1)" :key="index" class="preview-container">
              <vista-previa-sms
                  :body="row.message"
                  :header="llenarHeader(row)"/>
            </div>
          </div>
          <hr>
          <div class="col-md-12 config-avanzado">
            <div class="accordion-item">
              <h2 class="accordion-header" @click="toggleSection('prueba')">
                <button
                    :class="['accordion-button', { 'expanded': activeSection === 'prueba' }]"
                    type="button">
                  Realizar envio de prueba
                </button>
              </h2>
              <transition name="slide-fade">
                <div v-if="activeSection === 'prueba'" class="accordion-collapse collapse show">
                  <div class="accordion-body">
                    <!-- Contenido expandido de Usar Id de Seguimiento -->
                    <label>Ingrese número de prueba</label>
                    <div class="row">
                      <div class="col-sm-6">
                          <argon-input
                              v-model="numValue"
                              class="multisteps-form__input"
                              type="text"
                              :disabled="dataPruebas.length > 2"
                              placeholder="56987654321"
                          />
                          <argon-button
                              type="button"
                              color="celcom"
                              variant="gradient"
                              class="text-white ms-auto mb-0 js-btn-next me-5"
                              :disabled="dataPruebas.length > 2"
                              @click="guardarNumPrueba"
                          >  Agregar
                          </argon-button>
                          <argon-button
                              type="button"
                              color="celcom"
                              variant="gradient"
                              class="text-white ms-auto mb-0 js-btn-next"
                              :disabled="dataPruebas.length === 0"
                              @click="realizarTest"
                          >  Probar
                          </argon-button>
                      </div>
                      <div class="col-sm-6">
                        <span
                            v-for="id in dataPruebas"
                            :key="id" class="badge badge-celcom-orange clickable-badge mt-3 mt-md-0"
                            @click="quitarDataPrueba(id)"
                        >{{ id }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
        <div class="card-footer border-1">
          <argon-button
              class="mt-0 d-block ml-auto"
              @click.prevent="nextStepValor"
          >
            <i class="fas fa-paper-plane"></i> Enviar
          </argon-button>
        </div>
      </div>
    </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import VistaPreviaSms from "@/views/envios/components/VistaPreviaSms.vue";
import WhatsappMessagePreview from "@/views/envios/whatsapp/Add/components/VistaPrevia.vue"
import ArgonInput from "@/components/ArgonInput.vue";
export default {
  name: "ProgramadaEditAdd4",
  components: {
    ArgonInput,
    ArgonButton,
    VistaPreviaSms,
    WhatsappMessagePreview,
  },
  props: {
    selected: {
      type: Array,
      default: () => {
        return [];
      },
    },
    sendingsms: {
      type: Array,
      default: () => {
        return [];
      },
    },
    sendingwhatsapp: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      numValue: "",
      dataPruebas: [],
      activeSection: null, // Controla qué sección está expandida
      tiposBotones: [
        { value: "RR", img: "https://gs-upload.gupshup.io/whatsapp/reply.svg", tipo: "QUICK_REPLY" },
        { value: "URL", img: "https://gs-upload.gupshup.io/whatsapp/preview_call2.webp", tipo: "URL" },
        { value: "NT", img: "https://gs-upload.gupshup.io/whatsapp/preview_call1.webp", tipo: "PHONE_NUMBER" },
        { value: "CC", img: "https://gs-upload.gupshup.io/whatsapp/copy_code.svg", tipo: "QUICK_REPLY" },
      ],
      medioBotones: [
        { value: "IMG", img: "https://gs-upload.gupshup.io/whatsapp/media_image.svg", tipo: "MEDIA_IMG" },
        { value: "VIDEO", img: "https://gs-upload.gupshup.io/whatsapp/media_video.svg", tipo: "MEDIA_VIDEO" },
        { value: "DOCUMENT", img: "https://gs-upload.gupshup.io/whatsapp/media_document.svg", tipo: "DOCUMENT" },
        { value: "LOCATION", img: "https://gs-upload.gupshup.io/whatsapp/media_location.svg", tipo: "LOCATION" },
      ],
      media: {
        default: null,
        previewImage: null,
        previewVideo: null,
        previewPdf: null,
      },
      sendingLocal: [],
      header: {
        msisdn: "",
      },
    };
  },
  watch: {
    sendingsms: {
      handler(newVal) {
        this.sendingLocal = newVal ? newVal.sendingSmsDetailList : [];
      },
      immediate: true
    },
    sendingwhatsapp: {
      handler(newVal) {
        this.sendingLocal = newVal ? newVal.sendingSmsDetailList : [];
      },
      immediate: true
    }
  },
  methods: {
    async realizarTest() {
      if (this.$store.state.seleccion === 'SMS') {
        await this.$parent.realizarTest(this.dataPruebas);
      }else if (this.$store.state.seleccion === 'Whatsapp') {
        await this.$parent.realizarTestWhatsapp(this.dataPruebas);
      }
    },
    guardarNumPrueba() {
      this.dataPruebas.push(
         this.numValue
      );
      this.numValue = "";
    },
    quitarDataPrueba(name) {
      this.dataPruebas = this.dataPruebas.filter((data) => data !== name);
    },
    toggleSection(section) {
      this.activeSection = this.activeSection === section ? null : section;
    },
    mediaType(media) {
      const parsedMedia = JSON.parse(media);
      if (parsedMedia) {
        if (parsedMedia.type === "image") {
          this.media.default = this.medioBotones.find((tipo) => tipo.value === "IMG");
          this.media.previewImage = parsedMedia.image.link;
        } else if (parsedMedia.type === "video") {
          this.media.default = this.medioBotones.find((tipo) => tipo.value === "VIDEO");
          this.media.previewVideo = parsedMedia.image.link;
        } else if (parsedMedia.type === "pdf") {
          this.media.default = this.medioBotones.find((tipo) => tipo.value === "DOCUMENT");
          this.media.previewPdf = parsedMedia.image.link;
        }
      }
      return this.media;
    },
    botonesData(row) {
      const botones = [];
      if (row.whatsappButtonList) {
        row.whatsappButtonList.forEach((reply) => {
          const boton = this.tiposBotones.find((tipo) => tipo.tipo === reply.buttonType);
          botones.push({
            img: boton.img,
            texto: reply.buttonName,
            title: boton.tipo,
          });
        });
      }
      return botones;
    },
    llenarHeader(row) {
      this.header.msisdn = row.phoneCode + " " + row.phone;
      return this.header;
    },
    async nextStepValor() {
      if (this.$store.state.seleccion === 'SMS') {
        await this.$parent.envioServices();
      }else if (this.$store.state.seleccion === 'Whatsapp') {
        await this.$parent.envioServicesWhatsapp();
      }
    },
  },

};
</script>
<style scoped>
.preview-container {
  max-width: 400px; /* Ancho similar a un teléfono móvil */
  width: 100%; /* Asegura que ocupe el 100% del ancho disponible dentro de este contenedor */
  margin: 0 auto; /* Centra horizontalmente */
}
.config-avanzado .accordion-item {
  margin-bottom: 10px;
  border: 1px solid #ddd;
}

.config-avanzado .accordion-button:hover {
  background-color: #eaeaea;
}

.config-avanzado .accordion-button:after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  content: "\f106";
  margin-left: auto;
  color: rgba(33, 37, 41, 0.5);
  transition: all 0.2s ease-in-out;
}
.config-avanzado .accordion-button.expanded:after {
  content: "\f107"; /* Ícono alternativo cuando se expande */
}
.config-avanzado .accordion-body {
  padding: 15px;
  background-color: #eaeaea;
  border: 0;
}

.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all 0.5s ease;
}
.slide-fade-enter-from, .slide-fade-leave-to /* .slide-fade-leave-active en Vue 2 */ {
  transform: translateY(-10px);
  opacity: 0;
}
.switch-url.form-switch .form-check-input:after {
  width: 1rem !important;
  height: 1rem !important;
}
</style>
