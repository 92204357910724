<template>
  <div
      class="card multisteps-form__panel border-radius-xl"
      data-animation="FadeIn"
  >
    <div class="multisteps-form__content">
        <!-- Card body -->
        <div class="card-header border-1">
          <h5 class="font-weight-bolder mb-0" v-if="corregirValor">Asignando Segmentos y Tags<span class="font-weight-600">{{ createdList.name }}</span></h5>
          <h5 class="font-weight-bolder mb-0" v-else>Asignando Cabeceras</h5>
        </div>
        <div class="card-body">
          <div v-if="!corregirValor && $store.state.individual === false">
          <table class="table align-items-center mb-0">
            <thead>
              <tr class="thead-light">
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Columnas de tu Archivo</th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Columnas CelcomFlex</th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"></th>
              </tr>
            </thead>
            <tbody>
            <tr v-for="(tagL, index) in $store.state.cabeceras" :key="index" class="thead-light">
              <td class="align-middle text-sm">{{ tagL }}</td>
              <td class="align-middle text-sm">
                <dropdown-menu
                    class="v-dropdown-corto w-100"
                    :overlay="false"
                    :is-open="isDropdownOpen[index]"
                >
                  <template #trigger>
                    <button @click="toggleDropdown(index)" class="form-control d-flex justify-content-between align-items-center">
                      {{ selectedList[index] === null ? "Elegir Cabecera" : (selectedList[index] === "agregar" ? tagL : traducirHeader(selectedList[index])) }}<i class="fas fa-chevron-down"></i>
                    </button>
                  </template>
                  <template #body>
                    <div style="height:320px; overflow-y: scroll;">
                      <div class="dropdown-item bg-celcom-orange" @click="selectItem('agregar', index)">
                        <label>Agregar</label>
                      </div>
                      <div v-for="(tag, tagIndex) in $store.state.responseCustomer" :key="tagIndex" class="dropdown-item" @click="selectItem(tag.name, index)">
                        <label class="fw-normal">{{ traducirHeader(tag.name) }}</label>
                      </div>
                    </div>
                  </template>
                </dropdown-menu>
              </td>
              <td class="align-middle text-sm">
                <div class="text-secondary text-xxs font-weight-bolder opacity-7 align-middle text-sm">
                  {{ getEjemplo(selectedList[index]) }}
                </div>
              </td>
            </tr>

            </tbody>
          </table>
          </div>
          <div v-else-if="$store.state.individual === true && $store.state.vista2 === false">
            <AddContactInd/>
          </div>
          <div v-else-if="$store.state.vista2 === true && !corregirValor">
            <AddContactInd2/>
          </div>
          <div v-else>
            <div class="row">
              <div class="col-12 col-sm-6">
                <label>Elija un Segmento
                  <span type="button"
                        class="badge badge-celcom-orange"
                        title="Haz clic para agregar un Segment"
                        @click="modalSegment">
                        Agregar nuevo
                        <i class="fas fa-plus fa-xl"></i>
                  </span>
                </label>
                <select
                    :id="'selected2'"
                    v-model="selectedSeg"
                    :disabled="selectedSeg !== ''"
                    :class="['multisteps-form__select', 'form-control', 'form-select']"
                  >
                  <option value="" disabled selected>Segmentos</option>
                  <option v-for="(seg, tagIndex) in $store.state.responseSegmentos"
                          :key="tagIndex"
                          :value="seg"
                  >{{ seg.segmentTagName }}</option>
                </select>
              </div>
              <div class="col-12 col-sm-6">
                <label>Segmentos Elegidos</label>
                <br>
                <span
                    v-if="selectedSeg !== ''"
                    class="badge badge-celcom-orange me-1 clickable-badge"
                    @click="quitarSeleccionSegmentos"
                > {{ selectedSeg.segmentTagName }} </span>
                <span
                  v-else
                  class="badge badge-danger me-1"
                >Debe seleccionar un Segmento para continuar</span>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-12 col-sm-6">
                <label>Elija sus Tags
                  <span type="button"
                        class="badge badge-celcom-orange"
                        title="Haz clic para agregar un Tag"
                        @click="modalTag">
                        Agregar nuevo
                        <i class="fas fa-plus fa-xl"></i>
                  </span>
                </label>
                <select
                    :id="'selected3'"
                    v-model="selectedTag"
                    :class="['multisteps-form__select', 'form-control', 'form-select', { 'invalid-select': selectedList[index] === null }]"
                    @change="actualizarSeleccionTags"
                  >
                  <option value="" disabled selected>Tags</option>
                  <option v-for="(tag, tagIndex) in $store.state.responseTags"
                          :key="tagIndex"
                          :value="tag"
                  >{{ tag.segmentTagName }}</option>
                </select>
              </div>
             <div class="col-md-6">
                <label>Tags Elegidos</label>
               <br>
               <span
                   v-for="tag in seleccionTags"
                   :key="tag.segmentTagName"
                   class="badge badge-celcom-orange me-1 clickable-badge"
                   @click="quitarseleccionTags(tag.segmentTagId)"
               > {{ tag.segmentTagName }} </span>
              </div>
            </div>
            <hr v-if="$store.state.individual === false">
            <div v-if="$store.state.individual === false" class="row">
              <div class="col-12 col-sm-6">
                <br>
                <div class="form-check">
                  <input
                      v-model="eliminarActive"
                      class="form-check-input"
                      type="checkbox"
                      id="eliminarCheckbox"
                  />
                  <label class="form-check-label" for="eliminarCheckbox">
                    Eliminar contactos duplicados
                  </label>
                </div>
              </div>
              <div class="col-md-6">
<!--                <label>Tags Elegidos</label>-->
                <br>
                <span
                    :class="['badge', eliminarActive ? 'badge-danger' : 'badge-info', 'me-1', 'clickable-badge']"
                    @click="quitarSeleccionSegmentos"
                > {{ eliminarActive ? "Seran eliminados los contactos duplicados dentro de su lista" : "Su lista quedara con los mismos contactos" }} </span>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer border-1 d-flex mt-2">
          <argon-button
              type="button"
              color="celcom"
              variant="gradient"
              class="text-white ms-auto mb-0 js-btn-next"
              :disabled="corregirValor && selectedSeg === ''"
              @click.prevent="corregirValor ? tomarDatosPorFilas() : siguiente()"
          >
            Siguiente <i class="fas fa-chevron-right ms-2"></i>
          </argon-button>
        </div>
      </div>
      <modal :is-visible="modalAddSegment">
        <div class="multisteps-form__content">
          <div class="row mt-3">
            <div class="col-12 col-sm-10">
              <h6>Segmento</h6>
              <argon-input
                  v-model="segmentValor"
                  class="multisteps-form__input"
                  type="text"
                  placeholder="Valor"
              />
            </div>
          </div>
          <div class="modal-footer d-flex justify-content-between px-0">
            <button class="btn bg-gradient-success mt-2 mb-0" @click.prevent="addSegment">Agregar</button>
            <button class="btn bg-gradient-dark mt-2 mb-0" @click.prevent="closeModal">Cancelar</button>
          </div>
        </div>
      </modal>
      <modal :is-visible="modalAddTag">
        <div class="multisteps-form__content">
          <div class="row mt-3">
            <div class="col-12 col-sm-10">
              <h6>Tag</h6>
              <argon-input
                  v-model="tagValor"
                  class="multisteps-form__input"
                  type="text"
                  placeholder="Valor"
              />
            </div>
          </div>
          <div class="modal-footer d-flex justify-content-between px-0">
            <button class="btn bg-gradient-success mt-2 mb-0" @click.prevent="addTag">Agregar</button>
            <button class="btn bg-gradient-dark mt-2 mb-0" @click.prevent="closeModal">Cancelar</button>
          </div>
        </div>
      </modal>

    </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import AddContactInd from "@/views/contactsC/components/AddContactInd.vue";
import AddContactInd2 from "@/views/contactsC/components/AddContactInd2.vue";
import Modal from "@/components/Modal/Modal.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import DropdownMenu from 'v-dropdown-menu';
import {traducir_header} from "@/config/servicios/campana/util";

export default {
  name: "ListaAdd2",
  components: {
    ArgonInput,
    Modal,
    ArgonButton,
    AddContactInd,
    AddContactInd2,
    DropdownMenu
  },
  props: {
    createdList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    createdContact: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  emits: ['next'],
  data() {
    return {
      ejemplos_cabeceras: {
        "first_name": "Ej: Felipe",
        "last_name": "Ej: Perez",
        "address": "Ej: Calle 123",
        "msisdn": "Ej: 123456789",
        "tax": "Ej: 123456789",
        "country": "Ej: Chile",
        "company": "Ej: Celcom",
        "gender": "Ej: Masculino",
        "birth_date": "Ej: 01/01/1990",
        "email": "Ej: ejemplo@gmail.com",
      },
      eliminarActive: false,
      isDropdownOpen: [],
      segmentValor: "",
      tagValor: "",
      modalAddSegment: false,
      modalAddTag: false,
      segmentTag: {
        segmentTagId: null,
        type: "",
        segmentTagName: ""
      },
      corregirValor: false,
      seleccionTags: [],
      selectedList: [], // Inicializar como un array vacío
      selectedTag: "",
      selectedSeg: "",
      data: {
        customerFieldId: null,
        customerFieldName: ""
      }
    };
  },
  computed: {
    // Propiedad computada para determinar el valor inicial del v-model
    initialSelectedList() {
      const initialList = [];

      for (let j = 0; j < this.$store.state.cabeceras.length; j++) {
        const currentCabecera = this.$store.state.cabeceras[j];
        let found = false;

        for (let i = 0; i < this.$store.state.responseCustomer.length; i++) {
          const currentName = this.$store.state.responseCustomer[i].name;
          const currentAlias = this.$store.state.responseCustomer[i].alias;

          if (currentAlias.includes(currentCabecera)) {
            initialList.push(currentName);
            found = true;
            break;
          }
        }

        if (!found) {
          initialList.push(null);
        }
      }

      return initialList;
    }
  },
  mounted() {
    if (this.$store.state.editarContacto) {
      this.llenarDatosEdit();
    }
  },
  methods: {
    getEjemplo(tag) {
      // Si no hay selección, no mostrar nada
      if (!tag) return '';
      if (tag === 'agregar') return 'Campo Custom';
      // Si la selección está en ejemplos_cabeceras, devuelves el ejemplo correspondiente
      return this.ejemplos_cabeceras[tag.toLowerCase()] || 'Campo Custom';
    },
    quitarseleccionTags(id) {
      this.seleccionTags = this.seleccionTags.filter(
          (tag) => tag.segmentTagId !== id
      );
    },
    quitarSeleccionSegmentos() {
      this.selectedSeg = "";
    },
    traducirHeader(name){
      return traducir_header(name);
    },
    toggleDropdown(index) {
      this.$nextTick(() => {
        this.isDropdownOpen[index] = !this.isDropdownOpen[index];
      });
    },
    selectItem(name, index){
      this.selectedList[index] = name;
      this.$nextTick(() => {
        this.isDropdownOpen[index] = false;
      });
    },
    llenarDatosEdit() {
      this.selectedSeg = this.$store.getters.getSegmento(this.$store.state.dataEditarContacto.segmentId);
      this.$store.state.dataEditarContacto.contactPlanerTagList.forEach((tag) => {
        this.seleccionTags.push(this.$store.getters.getTag(tag.tagId));
      });
    },
    closeModal() {
      this.modalAddSegment = false;
      this.modalAddTag = false;
      this.segmentValor = "";
      this.tagValor = "";
    },
    modalSegment(){
      this.modalAddSegment = true;
    },
    modalTag(){
      this.modalAddTag = true;
    },
    addSegment() {
      this.segmentTag.type = "SEGMENT";
      this.segmentTag.segmentTagName = this.segmentValor;
      this.$store.getters.segmentAdd(this.segmentTag).then(async (response) => {
        await this.$store.getters.segmentosGet(); // Llama a segmentosGet para actualizar los datos
        this.selectedSeg = response.data;
        this.closeModal();
      });
    },
    addTag(){
      this.segmentTag.type = "TAG";
      this.segmentTag.segmentTagName = this.tagValor;
      this.$store.getters.tagAdd(this.segmentTag).then(async (response) => {
        await this.$store.getters.tagsGet(); // Llama a tagGet para actualizar los datos
        this.seleccionTags.push(response.data);
        this.closeModal();
      });
    },
    actualizarSeleccionTags() {
      // Actualiza seleccionTags con los valores seleccionados
      if (!this.seleccionTags.includes(this.selectedTag)){
        // Si no existe, agrégalo
        this.seleccionTags.push(this.selectedTag);
        this.selectedTag = "";
      }
    },
    async siguiente() {
      if (this.$store.state.individual === true && this.$store.state.vista2 === false){
        await this.$store.dispatch('cambiarVista2', true);
        //guardar valores que ingreso como genericos
      }else {
        this.corregirValor = true;
      }
    },
    tomarDatosPorFilas() {
      if (!this.$store.state.individual === true) {
        // Iterar a través de las filas y acceder solo a los valores seleccionados de la segunda columna
        const datosPorFilas = this.selectedList.map((valor, index) => {
          const primeraColumna = this.$store.state.cabeceras[index];
          return {
            primeraColumna: primeraColumna,
            segundaColumna: valor === "agregar" ? "agregar" : valor
          };
        });

        for (const datosPorFila of datosPorFilas) {
          if (datosPorFila.segundaColumna === "agregar") {
            this.data.customerFieldName = datosPorFila.primeraColumna;
            console.log(this.data);
            this.$store.getters.customer_field(this.data).then((response) => {
              console.log(response);
            });
          }
        }
        this.$store.dispatch('addatosPorFilas', datosPorFilas);
        this.$store.getters.msisdnGet();
      }else {
        for (let i = 0; i < this.$store.state.addCab.length; i++) {
            this.data.customerFieldName = this.$store.state.addCab[i].cabecera;
            this.$store.getters.customer_field(this.data).then((response) => {
              console.log(response);
            });
        }
      }
      this.$store.dispatch('cambiarDatoSegmento', this.selectedSeg);
      this.$store.dispatch('cambiarDatoTag', this.seleccionTags);
      this.$store.dispatch('cambiarEliminarActive', this.eliminarActive);
      this.$emit('next');
    },
    initializeSelectedList() {
      // Inicializar selectedList usando la propiedad computada
      this.selectedList = [...this.initialSelectedList];
      this.isDropdownOpen = new Array(this.$store.state.cabeceras.length).fill(false);
    },
  },
};
</script>
<style scoped>
.smaller-select {
  width: 100px; /* Ajusta el ancho según tus necesidades */
}
.invalid-select {
  border: 1px solid red;
  background-color: #ffe6e6; /* Cambia el color de fondo a rojo claro */
}
.modal {
  display: none;
}

.modal.is-active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  background: none;
  border: none;
}
.buttons {
  margin: 8px;
}
</style>