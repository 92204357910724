<template>
  <div
      class="card multisteps-form__panel border-radius-xl bg-white"
      data-animation="FadeIn"
  >
    <div class="card-header border-1">
      <h5 class="font-weight-bolder mb-0">Gestión de Contactos</h5>
    </div>
    <div class="card-body multisteps-form__content">

      <div v-if="!indLista" class="col-12 col-sm-6 d-flex align-items-center">
        <div
            class="form-check"
            :style="{ 'pointer-events': !indLista ? 'auto' : 'none' }"
        >
          <input
              id="lista"
              v-model="seleccion"
              type="radio"
              name="lista"
              value="lista"
              class="form-check-input"
          />
          <label for="lista">Importar lista (csv, excel, etc)</label>
        </div>
      </div>
      <div
          v-if="$store.state.editarContacto === false && !indLista"
          class="row mt-3"
          :style="{
            'pointer-events': seleccion === 'lista' ? 'auto' : 'none' ,
            'color': seleccion === 'lista' ? 'black' : '#ccc',  // Cambia el color del texto cuando está deshabilitado
            'opacity': seleccion === 'lista' ? '1' : '0.275'      // Reduce la opacidad cuando está deshabilitado
            }"
      >
        <div class="col-12 col-sm-6">
          <label>Nombre de la lista</label>
          <argon-input
              v-model="listaName"
              class="multisteps-form__input"
              type="text"
              placeholder="Nombre de la lista"
          />
        </div>
        <div class="row mt-3">
          <div class="col">
            <div class="card card-body">
              <div
                  id="dropzone"
                  class="form-control dropzone"
              >
                <div class="fallback">
                  <input name="file" type="file" ref="fileInput" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 d-flex align-items-center">
        <div class="form-check">
          <input
              id="individual"
              v-model="seleccion"
              type="radio"
              name="individual"
              value="individual"
              class="form-check-input"
          />
          <label for="individual">Individual</label>
        </div>
      </div>
      <div
          class="row mt-3"
          :style="{
              'pointer-events': seleccion === 'individual' ? 'auto' : 'none',
              'color': seleccion === 'individual' ? 'black' : '#ccc',  // Cambia el color del texto cuando está deshabilitado
              'opacity': seleccion === 'individual' ? '1' : '0.275'      // Reduce la opacidad cuando está deshabilitado
          }"
      >
        <div class="col-12 col-sm-1">
          <argon-input
              id="codigo"
              v-model="codigo"
              class="multisteps-form__input code"
              placeholder="Código"
          />
        </div>
        <div class="col-12 col-sm-6 mt-3 mt-sm-0">
          <argon-input
              id="msisdn"
              v-model="msisdn"
              class="multisteps-form__input"
              type="number"
              placeholder="12345678"
          />
        </div>
        <div class="row mb-4">
          <div
              class="col-12 col-sm-6"
          >
            <label>Seleccione Lista</label>
            <select
                :id="'selected3'"
                v-model="selectedList"
                :disabled="indLista"
                class="multisteps-form__select form-control form-select"
            >
              <option value="" disabled selected>Listas</option>
              <option
                  v-for="(group, tagIndex) in $store.state.responserContactGroups"
                  :key="tagIndex"
                  :value="group.contactGroupId"
              >
                {{ group.name }}
              </option>
            </select>
          </div>
        </div>
      </div>


      <argon-alert
          v-if="createdContactAux.length > 0 && showAlert"
          ref="successAlert"
          type="success"
          dismissible
          class="alert alert-success"
      >¡Se importaron tus contactos!</argon-alert>
    </div>
    <div class="card-footer border-1 d-flex mt-2">
      <argon-button
        type="button"
        color="celcom"
        variant="gradient"
        class="text-white ms-auto mb-0 js-btn-next"
        @click="nextStepValor"
        >Siguiente <i class="fas fa-chevron-right ms-2"></i>
      </argon-button>
    </div>
    <modal :is-visible="modalHojas">
      <p><b>Seleccione la Hoja del Excel que este cargando</b></p>
      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <select
              v-model="hoja"
              class="form-control form-select"
          >
            <option value="" selected disabled>Hojas</option>
            <option v-for="option in listaHojas"
                    :key="option"
                    class="select-danger"
                    :value="option"
                    :label="option"></option>
          </select>
        </div>
      </div>
      <div class="modal-footer border-1 d-flex justify-content-between mt-4 px-0">
        <button class="btn bg-gradient-celcom-orange mt-2 mb-0" @click.prevent="seleccionarHoja">Agregar</button>
        <button class="btn bg-gradient-dark mt-2 mb-0" @click.prevent="closeModal">Cancelar</button>
      </div>
    </modal>
    <Loader ref="loader"/>
  </div>
</template>

<script>
import Dropzone from "dropzone";
import * as XLSX from "xlsx";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonAlert from "@/components/ArgonAlert.vue";
import Modal from "@/components/Modal/Modal.vue";
import Swal from "sweetalert2";
import Loader from "@/components/Modal/Loader.vue";
export default {
  name: "ListaAdd1",
  components: {
    Loader,
    ArgonAlert,
    ArgonInput,
    ArgonButton,
    Modal,
  },
  props: {
    createdContact: {
      type: Array,
      default: () => {
        return [];
      },
    },
    validForm: {
      type: Object,
      default: () => ({})
    },
    indLista: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update-values', 'contacto-agregado'],
  data() {
    return {
      modalHojas: false,
      selectedList: this.$route.params.id ? this.$route.params.id : "",
      selected1: null,
      selected2: null,
      createdContactAux: [],
      listaName: "",
      seleccion:"",
      archivoExcel: null,
      msisdn: (this.validForm.PhoneContact || null),
      codigo: this.validForm.CodePhoneContact,
      showAlert: true,
      hoja: "",
      listaHojas: [],
      fileData: null,
      myDropzone: null,
    };
  },
  watch: {
    validForm: {
      handler(newValidForm) {
        this.msisdn = newValidForm.PhoneContact;
        this.codigo = newValidForm.CodePhoneContact;
        this.listaName = newValidForm.listName;
      },
      immediate: true,
      deep: true,
    },
    indLista(newVal) {
      if (newVal) {
        this.seleccion = 'individual';
      } else {
        this.seleccion = 'lista';
      }
    },
  },
  async mounted() {
    console.log("Valor de indLista:", this.$store.state.editarContacto);
    if (this.indLista === true){
      this.seleccion = 'individual';
    }else {
      this.seleccion = 'lista';
    }
    await this.$store.getters.obtenerContactGroups();
    if (this.$store.state.editarContacto === false && !this.indLista) {
      Dropzone.autoDiscover = false;
      const dropzoneElement = document.getElementById("dropzone");

      const myDropzone = new Dropzone(dropzoneElement, {
        url: "/file-upload",
        acceptedFiles: ".csv,.xls,.xlsx",
        addRemoveLinks: true,
        dictDefaultMessage: "Arrastra tu archivo aquí.",
      });

      myDropzone.on("addedfile", (file) => {
        // Acción al agregar un archivo
        // Puedes acceder al archivo cargado usando el objeto 'file'

        this.cargarArchivo(file, myDropzone);
      });
    }else {
      this.msisdn = this.$store.state.dataEditarContacto.msisdn;
    }
  },
  methods: {
    selectHoja() {
      this.modalHojas = false;
    },
    closeModal() {
      this.modalHojas = false;
      this.myDropzone.removeAllFiles(true);
      this.listaName = "";
    },
    agregarContacto(createdContactAux) {
      // Emitir el evento para informar al padre sobre la modificación
      this.$emit('contacto-agregado', createdContactAux);
    },

    // Método que se llama cuando se hace clic en el botón "Siguiente"
    async nextStepValor() {
      if (this.archivoExcel !== null){
        await this.$store.dispatch('addCabeceras', this.archivoExcel[0]);
      }
      if (this.seleccion === 'individual') {
        await this.$store.dispatch('cambiarIndividual', true);
        await this.$store.dispatch('cambiarIndividualNumero', this.codigo + '' + this.msisdn);
        await this.$store.dispatch('cambiarContactGroupId', this.selectedList);
      }
      this.$emit('update-values', {
        msisdn: this.msisdn,
        codigo: this.codigo,
        listaName: this.listaName,});
    },
    formatFileName(text) {
      // Elimina la extensión .xlsx si existe
      let result = text.replace(/\.xlsx\b/, "");

      // Reemplaza todos los espacios por guiones bajos
      result = result.replace(/\s+/g, "_");

      return result;
    },
    cargarArchivo(file, myDropzone) {
      this.$refs.loader.showLoader();
      const input = document.getElementById("dropzone").querySelector("input[type='file']");
      const selectedFile = file || input.files[0];
      if (selectedFile) {
        this.listaName = this.formatFileName(selectedFile.upload.filename);
        const reader = new FileReader();
        reader.onload = (e) => {
          try {
            const data = new Uint8Array(e.target.result);
            this.fileData = data;
            this.myDropzone = myDropzone;
            const workbook = XLSX.read(data, { type: 'array' });

            // Verificar si el archivo tiene más de una hoja
            if (workbook.SheetNames.length > 1) {
              console.log('El archivo tiene más de una hoja:', workbook.SheetNames);
              this.listaHojas = workbook.SheetNames;
              this.modalHojas = true;
              // Mostrar un modal para que el usuario seleccione una hoja
              // Suponiendo que tienes un método para manejar la selección de hoja
            } else {
              // Si solo hay una hoja, procesarla directamente
              this.procesarHoja(workbook, workbook.SheetNames[0], myDropzone);
            }

          } catch (error) {
            this.$refs.loader.hideLoader();
            console.error('Error al procesar el archivo:', error);
          }
        };

        reader.readAsArrayBuffer(selectedFile);
      }
    },

    procesarHoja(workbook, sheetName, myDropzone) {
      const sheet = workbook.Sheets[sheetName];

      // Guardar la hoja en una variable de datos
      this.archivoExcel = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      // Filtrar filas vacías
      this.archivoExcel = this.archivoExcel.filter(row => row.some(cell => cell !== null && cell !== ''));

      if (this.archivoExcel.length > 0) {
       //this.carga(workbook, sheetName, myDropzone);
        this.agregarContacto(this.archivoExcel);

        // Ocultar alerta después de un tiempo si se cargaron contactos
        if (this.archivoExcel.length > 0) {
          setTimeout(() => {
            this.showAlert = false;
          }, 6000);
        }

        // Mostrar mensaje de éxito con SweetAlert
        Swal.fire({
          icon: 'success',
          title: '¡Éxito!',
          text: `${sheetName} procesada. Se han cargado ${this.archivoExcel.length - 1} contactos.`,
          showConfirmButton: false,
          timer: 4000, // Cerrar automáticamente el alert después de 4 segundos
        });

        // Ocultar el loader y limpiar los archivos subidos en Dropzone
        this.$refs.loader.hideLoader();
        myDropzone.removeAllFiles(true);
        console.log(`Hoja ${sheetName} procesada.`);
      } else {
        // Si no hay datos en la hoja
        this.$refs.loader.hideLoader();
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: `La hoja ${sheetName} está vacía o no tiene datos.`,
          showConfirmButton: true
        });
      }
    },
    carga(workbook, sheetName, myDropzone){
      const totalFilas = this.archivoExcel.length;
      const tamanoLote = 1000; // Tamaño del lote para procesar
      let filaInicio = 1;
      let dataObjeto = [];
      const procesarLote = () => {
        // Procesar un lote de filas
        for (let i = filaInicio; i < Math.min(filaInicio + tamanoLote, totalFilas); i++) {
          let filaObjeto = {};

          this.archivoExcel[0].forEach((key, index) => {
            filaObjeto[key] = this.archivoExcel[i] ? this.archivoExcel[i][index] : null; // Verificación para evitar errores si alguna fila está incompleta
          });

          dataObjeto.push(filaObjeto);
        }

        filaInicio += tamanoLote;

        // Si aún quedan filas por procesar, continúa con el siguiente lote
        if (filaInicio < totalFilas) {
          setTimeout(procesarLote, 0); // Pausa mínima antes de procesar el siguiente lote
        } else {
          // Finalizar el procesamiento
          this.createdContactAux = dataObjeto;
          this.agregarContacto(this.createdContactAux);

          // Ocultar alerta después de un tiempo si se cargaron contactos
          if (this.createdContactAux.length > 0) {
            setTimeout(() => {
              this.showAlert = false;
            }, 3000);
          }

          // Mostrar mensaje de éxito con SweetAlert
          Swal.fire({
            icon: 'success',
            title: '¡Éxito!',
            text: `${sheetName} procesada. Se han cargado ${this.createdContactAux.length} contactos.`,
            showConfirmButton: false,
            timer: 4000, // Cerrar automáticamente el alert después de 4 segundos
          });

          // Ocultar el loader y limpiar los archivos subidos en Dropzone
          this.$refs.loader.hideLoader();
          myDropzone.removeAllFiles(true);
          console.log(`Hoja ${sheetName} procesada.`);
        }
      };

      // Iniciar el procesamiento del primer lote
      procesarLote();
    },
    // Método para manejar la selección de hoja por parte del usuario
    seleccionarHoja() {
      const workbook = XLSX.read(this.fileData, { type: 'array' });
      this.procesarHoja(workbook, this.hoja, this.myDropzone);
      this.modalHojas = false; // Cerrar modal
    }
  },
  };
</script>
<style scoped>
.code {
  width: 70px;
}
/* Agrega estilos según tus necesidades */
.modal {
  display: none;
  z-index: 99999;
}

.modal.is-active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  background: none;
  border: none;
}
.buttons {
  margin: 8px;
}
</style>